.hypothesis-modal {
  &__modal.stl_modal {
    width: 760px !important;

    .stl_scroll {
      height: auto !important;
      max-height: calc(100vh - 13.75em) !important;
    }

    .stl_accordion {
      margin: 0;
    }
    .stl_accordion-header {
      text-align: center;
      font-family: 'Source Sans Pro Bold';
      font-size: 20px;
      margin-top: 24px;
      padding: 0 12%;

      @media (max-width: 575px) {
        text-align: left;
        padding: 0;
        justify-content: start;
      }

      .stl_accordion-arrow {
        font-size: 11px;
        margin-top: 6px;
      }
    }

    .stl_accordion-content {
      padding: 0;
      .stl_accordion-header {
        font-size: 16px;
        margin-top: 16px;
        margin-bottom: 16px !important;
        padding: 0;
      }
      .stl_accordion-content-box:last-child {
        margin-bottom: 1em;
      }
    }
  }
}
