.infos-service-sapiendo-card {
  margin-bottom: 20px !important;
  .stl_card-body {
    padding: 18px 33px !important;
  }
  &__round-icon {
    background: #010035;
    width: 24px;
    height: 24px;
    color: white;
    border-radius: 12px;
    display: flex;
    justify-content: center;
  }
}
