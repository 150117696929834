@import "~video-react/styles/scss/video-react";

.video-player {
  position: relative;

  .video-react .video-react-big-play-button {
    display: none;
  }
  .stl_btn {
    position: absolute !important;
    top: calc(50% - 48px / 2) !important;
    left: calc(50% - 240px / 2) !important;
    @media (max-width: 575px) {
      bottom: calc(50% - 40px / 2) !important;
    }
  }
}
