.article-modal {
  &__container.stl_modal {
    border-radius: 1px !important;
    width: auto !important;
    height: auto !important;
    @media (max-width: 575px) {
      width: calc(100% - 2rem) !important;
    }
    .stl_modal-content {
      padding-top: 30px !important;
      padding-right: 15px !important;
    }
    .stl_modal-close {
      top: 13px !important;
      right: 13px !important;
    }

    .stl_scroll {
      overflow-x: scroll;
      overflow-y: scroll;
      height: auto !important;
      max-height: 25rem;
    }
  }
  &__image {
    max-width: 700px;
  }
}
