.article-contacter-card {
  &__card-info-container {
    background: #F7F8FD;
    min-height: 246px;
    padding: 20px 0;
    margin-top: 26px !important;
    .calendar-button button {
      margin-top: 6px !important;
    }
  }
}
