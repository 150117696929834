.media-template {
  &__thematique-red-color {
    color: #e2010b;
    text-transform: uppercase;
    font-weight: bold;
  }
  &__line {
    border-left: 1px solid #000000;
    height: 17px;
    padding: 0px !important;
  }
  &__note {
    vertical-align: sub;
    margin-right: 7px;
  }
  @media (max-width: 1009px) {
    &__cover {
      width: 100% !important;
    }
  }
}
