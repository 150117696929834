.legend-image{
  &__level{
    font-size: 16px !important;
    @media (max-width: 575px) {
      font-size: 14px !important;
      line-height: 18px !important;
    }
  }

  &__rectangle {
    width: 37px;
    height: 24px;
    border: 1px solid #4B4642;
    
    &_blue {
      background: #4472C4; 
    }
    &_orange {
      background: #ED7D31; 
    }
    &_white {
      background: #FFFFFF; 
    }
  }
  
  &__chevron {
    height: 15px;
    transition: all 0.5s ease 0s;
    color: #303333;

    &--rotate {
      transform: rotateX(180deg);
    }
  }
}
