.home-caroussel {
  &__card-text {
    .stl_card-body {
      padding-right: 0 !important;
    }
    @media (max-width: 575px) {
      .stl_card-actions {
        justify-content: flex-end;
        li {
          width: auto !important;
        }
      }
    }
  }
  &__cover-container {
    position: relative;
  }
  &__cover-text-container {
    position: absolute;
    width: 100px;
    bottom: 0.5rem;
    left: 1rem;
    color: white;
  }
  .stl_card-cover &__cover-container &__cover-text-container img {
    display: inline;
  }
  &__timer-text {
    top: 0.5rem;
    position: absolute;
    padding-left: 10px !important;
  }
  &__article-description {
    color: black;
    font-family: "Source Sans Pro";
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
