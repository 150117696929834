.service-contenu-modal {
  &__container.stl_modal {
    .stl_scroll {
      overflow-x: hidden;
      overflow-y: scroll;
      height: calc(100vh - 13.75em);
    }
  }
  .logo {
      width: 100%;
  }
  
  &__content {
    .article-contenu__liste-margin4 {
      padding-left: 5%;
    }
  }
}
