.faq {
  &__accordion-globale {
    .stl_accordion-item {
      border: none;
    }
    .stl_accordion-header {
      display: flex;
      justify-content: center;
    }
  }
  &__accordion-globale-unclicked {
    .stl_accordion-item {
      border: 0 solid #dcdcdc;
      border-width: 0 0 1px;
    }
    .stl_accordion-header {
      display: flex;
      justify-content: center;
    }
  }
  &__accordion-particulier {
    .stl_accordion-item {
      border: 0 solid #dcdcdc;
      border-width: 0 0 1px;
    }
    .stl_accordion-header {
      display: flex;
      justify-content: flex-start;
    }
    .stl_accordion-content {
      margin-bottom: 1em;

      .stl_accordion-content-box {
        .stl_link--primarylink + .stl_link {
          margin: 0;
        }
      }
    }
  }
  .stl_link {
    margin: 0 !important;
  }
  &__content-list {
    padding-left: 6%;
    li {
      margin-bottom: 8px;
    }
  }
}
