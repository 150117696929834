.blockCGU {

  &__content{
    padding: 32px;
    margin-bottom: 15px;
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: 0 8px 25px 0 lightgray;
    background-color: #fff;
    font-size: 16px;
    line-height: 1.42857143;

    h1, h2, h3 {
      margin-top: 20px;
      margin-bottom: 10px;
      line-height: 1.2;
    }

    h1{
      font-size: 46px;
      display: block;
      font-weight: 300;
    }

    h2{
      font-size: 36px;
      font-weight: 400;
    }
  }
}