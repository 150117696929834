.simulateurEconomiesImpots {
  &__step {
    &-container {
      background: linear-gradient(180.29deg, #F4F5F6 127.53%, rgba(244, 245, 246, 0) 154.54%);
      padding: 20px 0;
    }
    &-content {
      background-color: #ffffff;
      padding: 20px 0;

      .stl_title {
        margin-bottom: 10px !important;
      }
    }
  }
  &__padding-container {
    @media(min-width: 576px){
      padding: 0 50px;
    }
  }
}
