.parcours-ris-preparation-modal {
  width: 1012px !important;

  &__link {
    color: #0072b9 !important;

    &-image {
      margin-left: 10px;
    }
  }

  .stl_scroll {
    overflow-x: hidden;
    overflow-y: scroll;
    border: none !important;
    height: auto !important;
    max-height: calc(100vh - 15rem) !important;
  }
  .stl_modal {
    @media (min-width: 576px) {
      overflow: hidden;
    }
  }
}
