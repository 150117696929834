.disclaimer-sei-modal {
  &__img{
    width: 147px !important;
    height: 80px !important;
  }
  .stl_scroll {
    overflow-x: hidden;
    overflow-y: scroll;
    border: none !important;
    height: auto !important;
    max-height: calc(100vh - 20rem) !important;
  }
  .stl_modal {
    @media (min-width: 576px) {
      overflow: hidden;
    }
  }
}
