.spinner {
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  $main-color: #087dbf;
  $inner-color: #ffffff;

  &__circle {
    position: relative;
    width: 90px;
    height: 90px;
    overflow: hidden;
    background-color: $main-color;
    border-radius: 100%;
    animation: spin 1s infinite linear;
  }
  &__circle-inner,
  &__circle-gradient {
    position: absolute;
    transform: translate(-50%, -50%);
  }

  &__circle-inner {
    width: 66px;
    height: 66px;
    top: 50%;
    left: 50%;
    background-color: $inner-color;
    border-radius: 100%;
  }

  &__circle-gradient {
    top: -78px;
    left: 12px;
    background-color: $main-color;
    width: 140px;
    height: 140px;
    transform: rotate(-45deg);
    background-image: -moz-linear-gradient(
      0deg,
      $main-color 0%,
      $inner-color 75%
    );
    background-image: -webkit-linear-gradient(0deg, 0%, $inner-color 75%);
    background-image: linear-gradient(0deg, $main-color 0%, $inner-color 75%);
  }
}
