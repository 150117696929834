.understand-simulation-modal {
  &__modal.stl_modal {
    border-radius: 1px !important;
    width: 760px !important;
    max-height: 791px !important;

    .stl_modal-content {
      padding: 28px 45px 28px 57px !important;
    }

    .stl_modal-close {
      top: 13px !important;
      right: 33px !important;
    }
  }

  &__modal-content {
    height: 640px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  &__text,
  &__text-container {
    line-height: 18px !important;
    text-align: justify !important;
  }

  &__text-logo {
    flex: 0 0 77%;
    max-width: 77%;
  }

  &__ul {
    margin-top: 0 !important;
    text-align: justify !important;
  }

  &__table-title {
    background-color: #fbe7e4;
    margin-bottom: 10px !important;
  }

  &__divider {
    margin: 12px 0 !important;
    background-color: #e55f50 !important;
  }

  &__arrow-container {
    display: flex;
    justify-content: center;
    align-self: center;

    .stl_icon {
      height: 14px !important;
      path {
        fill: #e55f50 !important;
      }
    }
  }

  &__no-margin p:first-of-type{
    margin-top: 0 !important;
  }
}
