.loader {
  border: 3px solid #fff;
  border-top: 3px solid #e2010b;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 8px;
  &__container {
    cursor: progress;
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
