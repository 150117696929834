.service-sapiendo-modal {
  &__container.stl_modal {
    width: 760px !important;
    .stl_modal-close {
      top: 13px !important;
      right: 33px !important;
    }
    .stl_scroll {
      overflow-x: hidden;
      overflow-y: scroll;
      height: calc(100vh - 13.75em);
    }
    .stl_accordion-header {
      margin-bottom: 16px !important;
      border: solid 1px #b6b6b6;
      padding: 5px;
      background-color: #ffffff;
    }
  }
  &__header-title {
    font-size: 32px !important;
    line-height: 39px !important;
    font-weight: 900 !important;
    @media (max-width: 1013px) {
      font-size: 24px !important;
      line-height: 30px !important;
    }
    @media (max-width: 575px) {
      font-size: 18px !important;
      line-height: 20px !important;
    }
  }
  &__red-underline {
    width: 141px;
    height: 5px;
    background: #e9041e;
  }
  &__liste-padding4 {
    @media (max-width: 1013px) {
      padding-left: 6% !important;
    }
    padding-left: 4% !important;
  }
  &__liste-margin-top0 {
    margin-top: 0 !important;
  }
}
