.asset-edit-modal {
  &__modal.stl_modal {
    border-radius: 1px !important;
    width: 760px !important;
    @media (max-width: 575px) {
      width: 95% !important;
    }

    .stl_modal-content {
      padding: 28px 59px 37px !important;
    }

    .stl_modal-close {
      display: none;
    }
  }

  &__margin-input-row {
    margin-top: 50px !important;
    margin-bottom: 25px !important;
    @media (max-width: 575px) {
      .stl_input-input {
        width: 130px !important;
        font-size: 30px !important;
        border: none !important;
        text-decoration: underline;
        text-align: end !important;
      }
      .ant-input-number-input-wrap,
      .stl_input__wrapper .stl_input-input-wrap {
        input {
          font-size: 37px;
          font-family: sourcesanspro, Arial, Helvetica, sans-serif;
          color: rgb(84, 84, 84);
          padding: 0 !important;
        }
      }
    }
    .stl_input-input-wrap {
      text-align: end;
    }
  }

  &__inputQuantity .stl_input__wrapper .stl_input-wrapper .stl_input-input {
    padding-right: 3.1rem;
  }
}
