.offre-per-modal {
  &__modal.stl_modal {
    border-radius: 1px !important;
    width: 868px !important;
    @media (max-width: 575px) {
      width: 349px !important;
    }
    .stl_modal-content {
      @media (max-width: 575px) {
        padding: 0 0 24px 0 !important;
      }
    }
    .stl_modal-close {
      z-index: 1;
    }
    .stl_scroll {
      overflow-x: hidden;
      height: 80vh;
      max-height: 750px;
      @media (max-width: 575px) {
        height: 90vh;
        max-height: 573px;
      }
    }
  }

  &__divider {
    height: 8px !important;
    max-height: 8px !important;
    background-color: #e9041e !important;
    @media (max-width: 575px) {
      height: 5px !important;
      max-height: 5px !important;
      background-color: #fe0000 !important;
    }
    @media (min-width: 576px) {
      margin-bottom: 38px !important;
      margin-top: 12px !important;
    }
  }
  &__container-haut {
    position: relative;
  }
  &__image {
    min-height: 243px !important;
  }
  &__container-absolute {
    position: absolute;
    padding-left: 14px;
    margin-top: 18px;
    margin-left: 20px;
  }
  &__container-contenu {
    padding-left: 16px;
    padding-right: 16px;
    margin: 20px 10px 10px !important;
  }
  &__bas-de-page {
    margin: 30px 10px 10px !important;
    @media (max-width: 575px) {
      text-align: left;
      margin-top: 20px !important;
    }
  }
  &__icon {
    .stl_icon .styled {
      width: 24px !important;
      height: 24px !important;
    }
    .stl_text {
      font-size: 15px;
      font-family: "Montserrat";
    }
  }
  &__tag {
    color: #ffffff;
    font-size: 12px;
    text-transform: uppercase;
    padding: 8px;
  }
}
