.article-contenu {
  &__liste {
    margin: 0;
    line-height: 30px;
  }
  &__liste-margin4 {
    margin: 0 4%;
    line-height: 30px;
  }
  &__liste-margin8 {
    margin: 0 15% 0 8%;
    line-height: 30px;
  }
  &__liste-margin15 {
    margin: 0 8% 0 15%;
    line-height: 30px;
    @media(max-width: 575px) {
      margin: 0;
    }
  }
  &__link {
    color: #545454 !important;
    text-decoration: underline;
    :hover {
      cursor: pointer;
    }
    & &-image {
      path {
        fill: #545454;
      }
    }
  }
  &__link-internal {
    color: #E2010B !important;
  }
  &__orange {
    color: #FF3300;
  }
  &__red {
    color: #E2010B;
  }
  @media(max-width:802px) {
    &__image {
      width:100%;
    }
  }
}
