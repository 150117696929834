.quantity {
  .stl_input-group-addon>.stl_input-quantity-button {
    border-right-style: none;
  }

  .stl_input-group-addon~.stl_input-group-addon .stl_input-quantity-button {
    border-left-style: none;
    border-right-style: solid;
  }
}
