.info-modal-fiabilite-ris {
  &__container {
    @media (max-width: 575px) {
      max-height: 653px !important;
      .stl_modal-content {
        padding: 1.5rem !important;
      }
    }
    @media (min-width: 576px) {
      .stl_modal-content {
        padding: 0.75rem 1rem 1rem 0.75rem !important;
      }
    }
    @media (max-width: 768px) {
      width: calc(100% - 2rem) !important;
    }
  }
  &__croix {
    width: 12px;
    height: 12px;
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 20px;
  }

  @media (max-width: 575px) {
    &__contenus-container {
      height: 400px;
      overflow: scroll;
      overflow-x: hidden;
      margin-bottom: 10px !important;
    }
  }

  @media (min-width: 576px) {
    &__row-scroll {
      max-height: calc(100vh - 13.75em);
      overflow: auto;
      overflow-x: hidden;
    }
  }
}
