.questionnaire-dashboard {

    &__questionnaire {
      .stl_modal-body {
        margin-top: 0.75rem;
        height: calc(100vh - 5rem) !important;
        position: relative;
        overflow: hidden;
        padding-top: 56, 25%;
      }
    }
    &__iframe {
      position: absolute;
      border: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 90%;
    }
  }