.expert-retraite {
  &__card {
    &-sapiendo-logo-wrapper {
      width: 100%;
    }
  }
  &__call{
    &-paragraph {
      background-color: rgba(244, 245, 246, 0.6);
      padding: 9px 22px;
    }
    &-link {
      text-decoration: none;
    }
  }
}
