.retirement-pension-estimation {
  &__line {
    margin-top: 21px !important;
    height: 0px;
    border-top: 1px solid #dcdcdc;
  }

  &__line_popin {
    margin-top: 10px !important;
    height: 0px;
    border-top: 1px solid #dcdcdc;

    @media (max-width: 575px) {
      margin-top: 21px !important;
    }
  }

  &__trimbloc-list {
    margin-top: 10px !important;
  }

  &__trimbloc-list-popin {
    margin-top: 10px !important;
  }

  &__container {
    margin-top: 5px !important;
    text-align: start !important;
  }

  &__duration {
    font-family: "Source Sans Pro Bold";
    font-size: 20px !important;
    line-height: 25px;
    width: 300px;
    color: #000000;
  }

  &__subtitle {
    font-family: "Source Sans Pro Regular";
    font-size: 16px !important;
    color: #000000;

    @media (max-width: 575px) {
      font-size: 15px !important;
      line-height: 15px;
      margin-bottom: 10px !important;
    }
  }
}