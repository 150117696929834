.footerAssu {
  border-top: solid 2px rgba(0, 0, 0, 0.02);
  bottom: 0;
  color: #777;
  left: 0;
  padding: 0;
  position: initial;
  right: 0;
  width: 100%;
  z-index: 999;

  @media print {
    display: none !important;
  }

  &__row {
    &__lightgrey {
      background: #f4f5f6;
    }
    &__grey {
      background: #545454;
    }
    &__black {
      background: #000000;
    }
  }

  & .stl_select__wrapper &__select.stl_select {
    background: none;
    color: white;
  }
  & .stl_select__wrapper .stl_select-item-option {
    padding: 0;
  }
  & .stl_select__wrapper .stl_select-item-option &__link {
    width: 100%;
    justify-content: left;
  }

  &__wrapper {
    width: 95%;
    margin: 0 auto;

    @media (min-width: 1024px) {
      width: 85%;
    }

    @media (min-width: 1441px) {
      width: 1220px;
    }
  }

  &__grey-row {
    height: 224px;
    padding-top: 44px !important;
    margin: 0px !important;
  }
  &__white-row {
    height: 103px;
  }
  &__white-row-text {
    font-family: "Source Sans Pro Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #545454;
  }
  &__grey-row-text {
    font-family: "Source Sans Pro Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    color: #ffffff;
  }
  &__grey-row-title {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
  &__blackrow-text, &__blackrow-text .stl_text {
    font-family: "Source Sans Pro Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    border: 0px !important;
    :hover {
      color: #e60028;
    }
  }
  @media (max-width: 575px) {
    &__row__black &__wrapper &__black-row {
      display: block;
    }
  }
  &__link {
    color: #333;
  }
  &__social {
    background-color: #333333;
    color: white;
    padding: 32px 16px;
    position: relative;
    display: flex;

    @media (max-width: 767px) {
      &__container {
        width: 100%;
      }
    }

    &__container {
      background-color: inherit !important;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 80%;

      &__left {
        display: flex;

        &__icon {
          margin-right: 10px;
        }

        &__link {
          margin-top: 2px;
          color: #ffffff;
          text-decoration: none;
          font-weight: 600;
          cursor: pointer;
        }
      }

      &__right {
        display: flex;
        width: 100%;
        justify-content: flex-end;

        &__icon {
          cursor: pointer;
          height: 30px;
          vertical-align: middle;
          width: 37px;
          margin-right: 30px;
        }
      }
    }
  }

  &__links {
    @media (max-width: 767px) {
      &__container {
        flex-direction: column !important;
        height: auto !important;
        justify-content: space-evenly !important;
      }
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 62px;
      margin: 0px auto;

      @media (min-width: 768px) and (max-width: 1023px) {
        margin-right: 16px;
        margin-left: 16px;
        width: auto;
      }

      @media (min-width: 1024px) {
        width: 85%;
      }

      @media (min-width: 1441px) {
        width: 1220px;
      }

      @media (max-width: 767px) {
        &__right {
          flex-direction: column;
          align-items: center;
        }
      }

      &__right {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }
    }
  }
}
