.expert-retraite-infos {
  &__appel-container {
    background-color: #010035;
    padding: 8px 15px;
    @media(max-width:775px) {
      text-align: center;
    }
  }
  &__image {
    vertical-align: bottom;
    padding-right: 0px;

    @media (max-width: 350px) {
      width: 100%;
    }
  }
}
