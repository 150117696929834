.legal-notices-modal {
  &__black-text {
    color: black !important;
    line-height: 18px !important;
  }

  &__img {
    width: 140px;
    height: 90px;
    margin-top: -12px;
  }

  .stl_scroll {
    overflow-x: hidden;
    overflow-y: scroll;
    border: none !important;
    height: auto !important;
    max-height: calc(100vh - 20rem) !important;
  }

  .stl_modal {
    @media (min-width: 576px) {
      overflow: hidden;
    }
  }
}