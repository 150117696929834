.side-bar-chat-bot {

  .stl_grid_col:first-child {
    display: block !important;
  }
  .stl_avatar {
    background-color: #fff !important;
    box-shadow: 0px 5px 12px rgba(0, 4, 8, 0.25) !important;
    border: none !important;
    flex: unset !important; // A cause du stretch parent
  }

  &.animated {
    animation-name: appear400Delay;
    animation-duration: 3s;
  }

  &__chat-sg.stl_card {
    border-radius: 18px 18px 18px 0px !important;
  }

  &__chat-link,
  &__chat-sg {
    box-shadow: 0 2px 10px 0 rgba(0,0,0,.2) !important;

    &.animated {
      animation-name: appear200Delay;
      animation-duration: 2s;
    }
  }
  .ant-avatar {
    box-shadow: 0px 5px 12px rgba(0, 4, 8, 0.25) !important;
  }
  &__chat-link.stl_card {
    border-radius: 18px 18px 0px 18px !important;
    margin: 0px !important;

    @media (min-width: 1400px) {
      width: 296px;
    }
  }
  &__chat-link .stl_card-body {
    padding: 11px 20px !important;
  }

  .stl_card-head {
    padding-bottom: 0 !important;
  }

  @keyframes appear400Delay {
    0% {
      opacity: 0;
      top: 400px;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }

  @keyframes appear200Delay {
    0% {
      opacity: 0;
      top: 200px;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }
}
