:root {
  --primary-color: #F05B6F;
  --hover-color: #EE425B;
  --disabled-opacity: 0.3;
}

body {
  margin: 0;
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-size: 14px;
  color: #333;
  input[type="text"],
  input[type="number"] {
    font-size: 16px;
  }  
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Semi Bold';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/SourceSansPro/SourceSansPro-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro Regular';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro Bold';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/SourceSansPro/SourceSansPro-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro Semi Bold';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/SourceSansPro/SourceSansPro-SemiBold.ttf') format('truetype');
}

.containerAssu {
  background-color: inherit !important;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 320px) and (max-width: 1023px) {
  .containerAssu {
    margin-right: 16px;
    margin-left: 16px;
    width: auto;
  }
}

@media (min-width: 1024px) {
  .containerAssu {
    width: 85%;
  }
}

@media (min-width: 1441px) {
  .containerAssu {
    width: 1220px;
  }
}

.containerAssuV2 {
  background-color: inherit !important;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1441px) {
  .containerAssuV2 {
    max-width: 1441px !important;
  }
}

.calendar-button .stl_btn__label {
  margin-left: 5px;
}

/* Corrections Firefox */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

/* Slider */
.ant-slider {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  height: 12px;
  margin: 10px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 2px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}

.ant-slider-with-marks {
  margin-bottom: 28px !important;
}

.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #545454;
  border-radius: 2px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -7px !important;
  background-color: #fff;
  border: solid 2px #545454;
  border-radius: 50%;
  -webkit-box-shadow: 0;
  box-shadow: 0;
  cursor: pointer;
  bottom: 0;
  -webkit-transition: border-color 0.3s, -webkit-box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, -webkit-box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), -webkit-box-shadow 0.6s,
    -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
  border-color: #545454;
  -webkit-box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12);
  box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12);
}
.ant-slider-handle:focus {
  border-color: #545454;
  outline: none;
  box-shadow: rgb(255 255 255) 0px 0px 0px 2px, #545454 0px 0px 0px 4px;
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #545454;
}
.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #545454;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #545454;
}

.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}

.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: #8a9496;
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
  min-width: fit-content;
  width: 50%;
  font-size: 16px;
  font-family: "Source Sans Pro";
}

.ant-slider-dot {
  position: absolute;
  top: -4px;
  width: 0px;
  height: 12px;
  margin-left: -4px !important;
  background-color: #fff;
  border: 2px solid #8a9496;
  cursor: pointer;
}

.ant-slider-tooltip {
  display: none;
}

/* Modales */
.stl_modal-wrap.stl_modal-centered .stl_modal {
  max-width: 95%;
  overflow: hidden;
  width: 37.5rem;
  .stl_scroll {
    border: none;
    margin: 0;
    padding: 0;
  }
  
  @media(max-width:775px) {
    &.child-scrollable {
      .stl_modal-content {
        padding: 38px 10px 25px 38px !important;
      }
      .stl_scroll {
        padding-right: 18px;
      }
    }
  }
}

/* Accordéons */
.stl_accordion .stl_accordion-item .stl_accordion-header .stl_accordion-arrow {
  width: auto;
  height: auto;
}

/* StringInput */
.containerAssu .stl_card-body {
  .stl_select__wrapper,
  .stl_input__wrapper {
    margin-bottom: 0.5rem;
  }
}

/* Utils */

.espaces-insecables {
  white-space: nowrap;
}

/* Styles Elyxir */

.react-datepicker-popper { // Correction du fait que les date-picker ne s'affichent pas par dessus les SelectInput qui ont aussi un z-index:1
  z-index: 2 !important;
}

.profile-taxation__slider-vertical { // Les divider verticaux n'ont pas de marge alors que les horizontaux si
  margin: 0 4px !important;
}

::placeholder { // Remplace la couleur des placeholders qui ressemble trop à celle des valeurs saisies
  color: #cccccc !important;
}

.stl_select-disabled { // Remplace la couleur des SGInput disabled qui sont trop cachés
  color: #cccccc !important;
}

.stl_select__label { // Remplace la couleur des libellés d'inputs disabled qui sont trop cachés
  color: #545454 !important;
}

.stl_input-disabled { // Remplace la couleur des SGInput disabled qui sont trop cachés
  color: #cccccc !important;
}

.stl_input__label { // Remplace la couleur des libellés d'inputs disabled qui sont trop cachés
  color: #545454 !important;
}

.stl_input-group-addon { // Supprimer le cursor pointer sur les addons de selectInput
  cursor: default !important;
}

.asset__sub-element { // Affiche les enfants de SCI avec un décalage
  margin-left: 50px !important;
}

.project-meteo-frame .stl_card-body { // Corrige l'alignement de la météo dans le composant ProjectMeteo
  display: flex !important;
  align-items: center;
}

.stl_input__unit--int { // fix suffixes de NumericInput avec + que un caractère
  font-family: montserrat, arial, sans-serif !important;;
  font-size: 1rem !important;
  background-color: inherit !important;
  text-transform: inherit !important;
}

.stl_input__unit__value { // fix suffixes de NumericInput avec + que un caractère
  color: #545454 !important;
}

.ant-empty-image { // Corrige l'affichage des selectInput quand pas de données à l'intérieur
  display: none;
}

.stl_select-item { // Réduit le padding des selectItems trop grands
  padding: 0.5rem !important;
}

.rc-slider-track { // Couleur des sliders
  background-color: #E2010B !important;
}

.rc-slider-handle { // Couleur des sliders
  background-color: #E2010B !important;
  border-color: #E2010B !important;
}

.react-autosuggest { // Stylisation de AutoSuggest en attendant que le composant SG fasse ce dont nous avons besoin
  &__container {
    position: relative;
  }

  &__input {
    outline: none;
    color: #545454;
    cursor: text;
    font-size: 1rem;
    font-family: sourcesanspro, Arial, Helvetica, sans-serif;
    font-weight: 600;
    height: 3rem;
    padding: 0.8125rem 1rem;
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: #010035;
    border-radius: 0.25rem;
    box-sizing: border-box;
    width: 100%;

    &__open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &__focused {
      outline: none;
    }
  }

  &__suggestions-container--open {
    background-color: #fff;
    border: 1px solid #aaa;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: block;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    position: absolute;
    top: 51px;
    width: 100%;
    z-index: 2;
  }

  &__suggestions-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__suggestion {
    cursor: pointer;
    padding: 10px 20px;

    &--highlighted {
      background-color: #c3c5c6;
    }
  }
}

.stl_icon--xxl { // Forcer la taille des icônes pour INTG et HML
  width: 4rem !important;
  height: 4rem !important;
}

.stl_icon--xl { // Forcer la taille des icônes pour INTG et HML
  width: 4rem !important;
  height: 4rem !important;
}

.stl_icon--xs { // Forcer la taille des icônes pour INTG et HML
  width: 2rem !important;
  height: 2rem !important;
}

// Mode demo : on a besoin des 2 pour différents niveaux de floutage
// (3 pour les textes courants et 5 pour des textes plus grands)
.blur-3 {
  filter: blur(3px);
}

.blur-5 {
  filter: blur(5px);
}

// Correction couleur tabs pour le coach
.stl_icon .styled__path--fill-highlight {
  fill: #E2010B !important;
}

// FIXME SGTabs 4.10.0 à supprimer en 4.14.0+
.stl_tabs__tabpane {
  width: 100%;
}
