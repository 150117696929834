@import 'website/styles';

.aggregate-step {
  &__container {
    @media (max-width: 767px) {
      padding: 12px 8px;
      background: linear-gradient(180deg, #F4F5F6 -16.22%, rgba(244, 245, 246, 0) 100%);
    }

    @media (max-width: 575px) {    
      margin-left: -16px !important;
      margin-right: -16px !important;
    }

  }
}
