.radio-group {
  &__black-raises {
    color: black;
  }

  &__button-radio {

    .stl_radio {
      margin-right: 0 !important;
    }
    &:first-child{
      margin-bottom: 0;
      margin-right: 0;
    }
  }

  &__container .stl_radio-group-wrapper {
      justify-content: center;
  }
}
