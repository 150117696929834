.service-card {
  background: #f4f5f6;
  box-shadow: 0px 2px 4px #bababa;
  position: relative;
  cursor: pointer;

  @media (max-width: 575px) {
    background: #fff;
    border-radius: 4px;
  }

  .divider {
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    background: #636c6e;
  }

  .right-filler {
    background: #ffffff;
    position: absolute;
    right: -15px;
    top: 0;
    width: 15px;
    height: 100%;
  }

  &.selected {
    background: #ffffff;

    .divider {
      background: #AD5C00;
    }
  }

  
}