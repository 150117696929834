.bloc-impot {
    &__header-text {
        color: black;
        &-value {
            color: #545454;
        }
    }
    &__table-header {
        color: black;
    }
    .stl_accordion {
        .stl_accordion-item {
            border: none;

            .stl_accordion-header {
                padding: 0;
            }
            .stl_accordion-content {
                margin-top: 1rem;
                border-top: 1px solid #dcdcdc;
                padding-top: 1rem;
            }
        }
    }
    .stl_progress {
      width: 100% !important; // Surcharge App.scss

      &-outer .stl_progress-inner {
        border-radius: 4px;

        .stl_progress-bg {
            height: 10px !important;
        }
      }
    }
}