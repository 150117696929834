.detail-tab {
  &__text-paragraph:not(:last-child) {
      padding-bottom: 32px;
  }
  &__hide-list-style {
    list-style-type: none;

    @media (max-width: 767px) {
      padding-inline-start: 0 !important;
    }
  }
}
