.information-sapiendo-card {
  margin-top: 0 !important;
  margin-bottom: 20px !important;
  background-color: #f4f5f6 !important;
  .stl_card-body {
    padding: 28px 18px !important;
  }
  &__appel-container {
    background-color: #010035;
    padding: 8px 15px;
  }
  &__infos-price-card {
    .stl_card-body {
      padding: 28px 16px 12px !important;
    }
  }
}
