.carrousel-video-carte-mobile {
  &__video-container {
    /* Styles .stl_card-cover img */
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
  }
}

.carrousel-video-carte-mobile__card .stl_card-cover{
  padding-bottom: 0.5rem;
}